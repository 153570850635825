import React from "react";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import Button from "../../../components/custom-widgets/button";
import List from "../../../components/custom-widgets/list";
import BestBanksDefault from "../../../components/best-banks/best-banks-default";
import TwoColumnSectionWithImage from "../../../components/custom-widgets/two-column-section-with-image";
import LogoWaFdPerks from "../../../images/workperks/wafd-and-work-perks-logo-lockup.svg";

const Perks = () => {
  const breadcrumbData = [
    {
      id: 1,
      url: "/business-banking",
      title: "Business Banking"
    },
    {
      id: 2,
      url: "/business-banking/business-checking-account",
      title: "Business Checking"
    },
    {
      id: 3,
      active: true,
      title: "Perks"
    }
  ];

  const title = "WaFd Work Perks - Business Bank Account Perks",
    description =
      "Learn about WaFd Work Perks, business bank perks offered to employees of your business if you have 10 or more employees with a business account at WaFd Bank.";

  const SEOData = {
    title: title,
    meta: [
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/business-banking/business-checking-account/perks"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-work-perks-082624.jpg"
      }
    ]
  };

  const compareBusinessAccountsCtaData = {
    text: "Compare Business Checking Accounts",
    containerClass: "mb-3 mb-lg-0",
    showIcon: false,
    url: "/business-banking/business-checking-account",
    class: "btn-link w-100 w-sm-auto"
  };

  const findABusinessBankerBtnData = {
    text: "Find a Business Banker",
    showIcon: false,
    url: "/business-banking/near-me",
    class: "btn-primary w-100 w-sm-auto"
  };

  const comparePersonalAccountsBtnData = {
    text: "Compare Personal Accounts",
    containerClass: "mb-3",
    showIcon: false,
    url: "/personal-banking/checking-account",
    class: "btn-link w-100 w-sm-auto"
  };

  const LeftContent = () => {
    return (
      <>
        <StaticImage
          quality="100"
          placeholder="blurred"
          className="mb-4"
          src="../../../images/workperks/thumbnail-work-perks-082624.jpg"
          alt="Group of laughing coworkers."
        />
        <h4>
          Your employees can enjoy a full-service account with 24-hour banking. Call us anytime at{" "}
          <a href="tel:800-324-9375" id="perks-c3-tel-link">
            800-324-9375
          </a>
          .
        </h4>
        <h4 className="font-weight-bold">Talk with your banker today to learn how to sign up!</h4>
        <Button id="find-a-business-banker-btn" {...findABusinessBankerBtnData} />
      </>
    );
  };

  const perksAccountsIncludeListData = {
    accountName: "perks-accounts-include-list",
    items: [
      {
        id: 1,
        text: "Online and mobile banking with bill pay and mobile deposit"
      },
      {
        id: 2,
        text: "Complimentary first box of checks"
      },
      {
        id: 3,
        text: "Free 3x5 Safe Deposit Box where available"
      },
      {
        id: 4,
        text: "Get an extra 0.10% over our published CD rates"
      },
      {
        id: 5,
        text: 'Complimentary access to <a href="/personal-banking/greenlight-kids-debit-card" id="perks-greenlight-link">Greenlight</a> to help kids learn how to manage their own money'
      },
      {
        id: 6,
        text: "Up to $5,000 off mortgage, refinance or custom construction loan origination fees**"
      },
      {
        id: 7,
        text: "1.5% discount for 6 months on published Home Equity Line of Credit rates***"
      },
      {
        id: 8,
        text: "Complimentary personal finance and budgeting tools"
      },
      {
        id: 9,
        text: 'Access to <a href="https://wafdbank.banzai.org/wellness/collections" id="perks-banzai-external-link" target="_blank" rel="noopener noreferrer">Banzai!</a> an interactive finance platform for all ages with tools, calculators, and more'
      },
      {
        id: 10,
        text: "Debit card with fee-free access to thousands of MoneyPass<sup>&trade;</sup> ATMs"
      },
      {
        id: 11,
        text: 'Person-to-person payments with <a href="/personal-banking/mobile-banking-app/zelle" id="perks-zelle-link">Zelle</a><sup>&reg;</sup>'
      }
    ]
  };

  const perksRewardsListData = {
    accountName: "perks-rewards-list",
    items: [
      { id: 1, text: "5¢ Per Gallon Gas Savings" },
      { id: 2, text: "Shopping Discounts" },
      { id: 3, text: "Travel and Leisure Discounts" },
      { id: 4, text: "Mobile Phone Insurance" },
      { id: 5, text: "Credit Monitoring and Identity Theft Resolution Service" },
      { id: 6, text: "Buyer's Protection/Extended Warranty" },
      { id: 7, text: "And More!" }
    ]
  };

  const RightContent = () => {
    return (
      <>
        <h3>
          Employees can choose an account with protection and coverage for their families or *Free Checking with full
          service functionality. With any account they choose, they'll get access to extras with Work Perks.
        </h3>
        <h3 className="text-success font-weight-bold">All Work Perks accounts include:</h3>

        <List {...perksAccountsIncludeListData} />

        <h3 className="text-success font-weight-bold">
          Choose Rewards, Premium Rewards, or Interest Checking to receive these additional benefits:
        </h3>

        <List {...perksRewardsListData} />

        <Button id="compare-personal-accounts-btn" {...comparePersonalAccountsBtnData} />

        <p className="text-gray-70 mb-0">
          All checking accounts require a $25 initial deposit to open. Some account benefits and rewards require
          registration and activation.
        </p>
      </>
    );
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section id="simple-small-business-checking-section" className="container">
        <h1>WaFd Bank Work Perks</h1>
        <div className="row">
          <div className="col-lg-7 col-xl-8">
            <h2 className="text-success">
              Offer perks to your employees at no cost, with any WaFd business checking account and 10 or more
              employees.
            </h2>
            <h4>
              Get everything you need in one account. Stick with the basics and open a *Free Business Checking account
              or upgrade with Remote Deposit Capture, eWires, Positive Pay Fraud Prevention, and much more.
            </h4>
            <Button id="compare-business-account-cta" {...compareBusinessAccountsCtaData} />
          </div>
          <div className="col-lg-5 col-xl-4 d-lg-flex justify-content-end">
            <img src={LogoWaFdPerks} alt="WaFd Bank and Work Perks logo." className="d-block" height="184" />
          </div>
        </div>
      </section>

      <TwoColumnSectionWithImage id="perks-account-info-section" contentOnLeft={false} isGray={true}>
        {{
          image: <LeftContent />,
          content: <RightContent />
        }}
      </TwoColumnSectionWithImage>

      <section id="disclaimer-section" className="container text-sm text-gray-60">
        <p>*Nonsufficient funds fees may apply.</p>
        <p>
          **Up to $5,000 off loan origination fees, including credit report, flood certification, and tax monitoring
          fees. This offer is available on owner-occupied, primary residence, purchase and refinance transactions, and
          custom construction loans. $5,000 off loan origination fee requires automatic mortgage payment via EZ-Pay from
          a WaFd checking account, payroll direct deposit, one or more online Bill Pay and monthly reserves. Financing
          for second homes, investment properties and lot loans are not eligible for discounts. All loans are subject to
          credit approval.
        </p>
        <p>
          ***Requires direct deposit and EZ Pay automatic payment option from your WaFd account. 6 months fixed
          introductory rate with a 1.5% discount; rate is variable thereafter.
        </p>
        <p>
          Certificate of Deposit (CD) requires just $1,000 to open. Includes IRAs. Maximum $100,000 account balance.
        </p>
        <p className="mb-0">
          WaFd Bank customers are eligible for the Greenlight SELECT plan at no cost when they connect their WaFd Bank
          account as the Greenlight funding source for the entirety of the promotion. Subject to minimum balance
          requirements and identity verification. Upgrades will result in additional fees. Upon termination of
          promotion, customers will be responsible for associated monthly fees. See terms for details. Offer ends
          02/03/2025. Offer subject to change and partner participation.
        </p>
      </section>

      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default Perks;
